<template>
    <FormTemplate v-if="form" :form="form">
        <b-form-group class="label-for-xl" label="Old Password" label-for="inputOldPassword">
            <b-form-input
                type="password" id="inputOldPassword" class="form-control form-control-xl"
                placeholder="enter your old password"
                v-model="form.data.oldPassword"
                :state="form.states.oldPassword"
            />
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.oldPassword }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group class="label-for-xl" label="New Password" label-for="inputNewPassword">
            <b-form-input
                type="password" id="inputNewPassword" class="form-control form-control-xl"
                placeholder="enter the new password"
                v-model="form.data.password"
                :state="form.states.password"
            />
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.password }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group class="label-for-xl" label="New Password Confirm" label-for="inputNewPasswordConfirm">
            <b-form-input
                type="password" id="inputNewPasswordConfirm" class="form-control form-control-xl"
                placeholder="enter the new password again"
                v-model="form.data.passwordConfirm"
                :state="form.states.passwordConfirm"
            />
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.passwordConfirm }}</b-form-invalid-feedback>
        </b-form-group>
    </FormTemplate>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';

export default {
    name: 'Forms.Form.ChangePassword',
    components: {
        FormTemplate
    },
    data(){
        return {
            form: null
        };
    },
    mounted(){
        this.form = new Form({
            oldPassword: "",
            password: "",
            passwordConfirm: "",
        },{
            onSubmit: (form) => {
                return this.$api.put('me/password',{
                    data: {
                        oldPassword: form.get('oldPassword'),
                        password: form.get('password'),
                        passwordConfirm: form.get('passwordConfirm'),
                    }
                })
            },
            onSuccess(form){
                form.resetData();
            }
        });
    }
};
</script>
